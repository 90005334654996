<template>
  <v-card height="400" width="256" class="mx-auto pt-6" dark>
    <v-navigation-drawer permanent>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> CRM </v-list-item-title>
          <v-list-item-subtitle> </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          router
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: "Nav",
  props: ["menuItems", "user_level"],
  data() {
    return {
      items: [
        { title: "Processos", icon: "list", route: "/leads" },
        {
          title: "Processos Inativos",
          icon: "list",
          route: "/leads-inactives",
        },
        { title: "Exportar Leads", icon: "import_export", route: "/Export" },
        { title: "Reports", icon: "timeline", route: "/Reports" },
        {
          title: "Análise de Dados",
          icon: "cable-data",
          route: "/Analise-dados",
        },
      ],
      right: null,
    };
  },
};
</script>

<style scoped>
.mx-auto {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.v-card {
  height: 100% !important;
  background-color: #333 !important;
}

.v-navigation-drawer {
  background-color: #333 !important;
}

.v-divider {
  border-width: medium 0 0 0;
}
</style>