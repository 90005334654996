import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/leads',
    name: 'leads',
    component: () => import('../views/Leads.vue'),
  },
  {
    path: '/leads-inactives',
    name: 'leads-inactives',
    component: () => import('../views/Leads-Inactives.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/Reports.vue'),
  },
  {
    path: '/export',
    name: 'export',
    component: () => import('../views/Export.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/data-analysis',
    name: 'Data-Analysis',
    component: () => import('../views/Data-Analysis.vue'),
  },
  // {
  //   path: '/uploads',
  //   name: 'uploads',
  //   component: () => import('../views/Uploads.vue')
  // },
  // {
  //   path: '/meudia',
  //   name: 'Dia',
  //   component: () => import('../views/Home2.vue'),
  // },
  {
    path: '/objetivos',
    name: 'Objetivos',
    component: () => import('../views/Objetivos.vue'),
  },

  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/Home.vue'),
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home2.vue'),
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let user_level = window.sessionStorage.getItem('user_level');
  let viewsLevel01 = ['home', 'Dia', 'leads'];
  let viewsLevel02 = [
    'Objetivos',
    'Data-Analysis',
    'export',
    'reports',
    'leads-inactives',
    'leads',
    'teste',
    'simulacoes',
  ];

  if (user_level == undefined && to.name !== 'login') next({ name: 'login' });
  else if (user_level == 1 && !viewsLevel01.includes(to.name))
    next({ name: 'home' });
  else if (user_level > 1 && !viewsLevel02.includes(to.name))
    next({ name: 'leads' });
  next();

  // if(to.name != 'Login' && name == undefined) next({ name: "Login" })
  // // else if((to.name === 'Login' || to.name === 'ChangePassword') && (name != null)) next({ name: "Home" });
  // else next();
});

export default router;
