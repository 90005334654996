<template>
  <v-app>
    <v-main>
      <Header :username="username" />
      <Nav :user_level="user_level" :menuItems="menuItems" />
      <router-view @logged="n += 1"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import Nav from "@/components/Nav";
export default {
  components: {
    Header,
    Nav,
  },
  name: "App",
  data() {
    return {
      n: 0,
    };
  },
  computed: {
    user_level() {
      this.n;

      return parseInt(window.sessionStorage.getItem("user_level")) || 0;
    },
    username() {
      this.n;

      return window.sessionStorage.getItem("name") || "";
    },
    menuItems() {
      this.n;

      let items = [];

      if (this.user_level > 1) {
        items = [
          { title: "Processos", icon: "list", route: "/leads" },
          {
            title: "Processos Inativos",
            icon: "mdi-playlist-remove",
            route: "/leads-inactives",
          },
          { title: "Exportar Leads", icon: "import_export", route: "/Export" },
          { title: "Reports", icon: "timeline", route: "/Reports" },
          {
            title: "Análise de Dados",
            icon: "mdi-chart-bar",
            route: "/data-analysis",
          },
          {
            title: "Objetivos",
            icon: "mdi-file-table",
            route: "/objetivos",
          },
        ];
      } else {
        items = [
          // { title: "O meu dia", icon: "calendar_today", route: "/" },
          { title: "O meu dia", icon: "calendar_today", route: "/" },
          { title: "As minhas Leads", icon: "list", route: "/leads" },
        ];
      }

      return items;
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.v-timeline-item__dot--small {
  height: 14px !important;
  left: calc(50% - 12px) !important;
  width: 14px !important;
}

.v-timeline-item__body {
  margin-top: -14px !important;
  left: -14px !important;
}
</style>