<template>
  <div>
    <v-app-bar color="#f3f3f3" dense height="80px">
      <img class="logo" src="../assets/images/Reorganiza_Logo-2.png" />
      <v-spacer></v-spacer>
      {{ username }}
      <template>
        <v-menu bottom origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon class="user-icon-btn ml-2" v-on="on">
              <v-icon class="user-icon" color="rgb(246, 147, 30)"
                >account_circle</v-icon
              >
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in itemsMenu"
              :key="i"
              @click="() => {}"
            >
              <v-list-item-title
                v-if="item.title == 'Logout'"
                @click="logout()"
                >{{ item.title }}</v-list-item-title
              >
              <v-list-item-title
                v-else-if="item.title == 'Change Password'"
                @click="passwordDialog = !passwordDialog"
                >{{ item.title }}</v-list-item-title
              >
              <v-list-item-title v-else>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <Password
      :dialog="passwordDialog"
      :username="username"
      @close-dialog="passwordDialog = !passwordDialog"
    />
  </div>
</template>


<script>
import Password from "./Password";
export default {
  name: "Header",
  props: ["username"],
  components: { Password },
  data() {
    return {
      itemsMenu: [{ title: "Change Password" }, { title: "Logout" }],
      passwordDialog: false,
    };
  },
  methods: {
    logout: async function () {
      window.sessionStorage.clear();
      this.$router.push("login");
    },
  },
};
</script>

<style scoped>
.logo {
  height: 80px;
}
.user-icon {
  font-size: 50px !important;
  margin-left: 5px !important;
}
.user-icon-btn {
  margin-right: 20px !important;
}
.v-toolbar__content {
  padding: 0 30px !important;
}

.v-app-bar {
  margin-left: 256px;
}
</style>