import Vue from 'vue';
import Vuex from 'vuex';

import api_calls from '@/modules/api_calls';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    all_leads: [], //all leads - Leads view
    single_lead: [], //used by SET_LEAD_DETAIL
    single_lead_process_id: [], //Lead Process detail
    single_lead_center_process_id: [], //Lead Center Process details
    report_data: [], //Content in reports table
    array_data: [],
    allow_sort_leads: false,
    leads_disabled: [], //Disabled Leads / Inactives leads
    leads_enabled: [],
    report_dates: [],
    messageOverlay: '',
    overlayStatus: false,
    overlayCancel: false,
    typeOfViewMode: '',
    consultores: '',
    btnExcelExport: true,
    possibleResults: '',
    leadHistory: '',
    processFiles: '',
    resultApi: '',
    insertData: [], //Data para adicionar coisas na edicao
    leadsFilters: '',
    leadsCallbacks: '',
    leadsStepByStep: '',
    newLeadsTotal: '',
    leadProducts: '',
    leadProcessResults: '',
    doneCallLeadsDisabled: false,
    doneCallLeadsEnable: false,
    rendimentos: 0, //Total de rendimentos para prit data e sect data
    despesas: 0, //Total de despesas para prit data e sect data
    colorBackgroundInput: 'teal', // Color Background dos Inputs relativos à Taxa de Esforço
    consultantGoal: 0, //Valor do objetivo do consultor
    historyClient: [],
    allKpis: [],

    // Habilita novos inputs de acordo com o produto selecionado
    saudeSeguradora: false, //Valor = Saúde
    automoveisSeguradora: false, //Valor = Automóvel
    numberOfChild: 0, // Numero de dependentes para seguros
    vidaSeguradora: false, //Valor = Vida
    vidaCreditoSeguradora: false, //Valor = Vida Crédito
    multirriscosSeguradora: false,
    outroSeguradora: false,

    simulador: [],
  },
  getters: {},
  mutations: {
    SET_GLOBAL_VARIABLE(state, payload) {
      state[payload.var] = payload.value;
    },
    SET_TWO_GLOBAL_VARIABLES(state, payload) {
      state[payload.var1] = payload.value1;
      state[payload.var2] = payload.value2;
    },
    SET_BTN_EXPORT_EXCEL(state, payload) {
      state.btnExcelExport = payload;
    },
    SET_TYPE_OF_VIEW(state, payload) {
      state.typeOfViewMode = payload;
    },
    SET_OVERLAY_DATA(state, payload) {
      state.overlayStatus = payload.overlay;
      state.messageOverlay = payload.message;
      state.overlayCancel = payload.cancel;
    },
    //Set dates in Report Vire:
    SET_DATES_REPORT(state, payload) {
      state.report_dates = payload;
    },
    SET_LEAD_CENTER_PROCESS(state, payload) {
      state.single_lead_center_process_id = payload;
    },
    SET_REPORT_DATA(state, payload) {
      state.report_data = payload;
    },
    SET_ARRAY_DATA(state, payload) {
      state.array_data = payload;
      state.allow_sort_leads = true;
    },
    SET_ALLOW_SORT_LEADS(state, payload) {
      state.allow_sort_leads = payload;
    },
    SET_INSERT_DATA(state, data) {
      //console.log('insert data', data);
      let array = state.insertData;
      state.insertData = array.concat(data);
    },
  },
  actions: {
    set_activate_lead: async function({ commit }, payload) {
      let data = await api_calls.set_activate_lead(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'resultApi', value: data });
    },
    set_new_lead: async function({ commit }, payload) {
      let data = await api_calls.set_new_lead(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'resultApi', value: data });
    },
    //Collect Consultores:
    collect_consultores: async function({ commit }) {
      let data = await api_calls.collect_consultores();

      console.log('data consultores', data);

      commit('SET_GLOBAL_VARIABLE', { var: 'consultores', value: data });
    },
    //Collect all Leads
    collect_leads: async function({ commit }, user) {
      let data = await api_calls.collect_leads(user.user_level, user.username);

      //Set all leads in Leads view:
      commit('SET_TWO_GLOBAL_VARIABLES', {
        var1: 'all_leads',
        value1: data,
        var2: 'doneCallLeadsEnable',
        value2: true,
      });
    },
    my_leads_in_process_new: async function({ commit }, username) {
      let data = await api_calls.my_leads_in_process_new(username);

      //Set all leads in Leads view:
      commit('SET_TWO_GLOBAL_VARIABLES', {
        var1: 'leads_enabled',
        value1: data,
        var2: 'doneCallLeadsEnable',
        value2: true,
      });
    },
    collect_lead_details: async function({ commit }, id) {
      let data = await api_calls.collect_lead_details(id);

      //Set all details for a specific lead
      commit('SET_GLOBAL_VARIABLE', { var: 'single_lead', value: data });
    },
    collect_lead_process: async function({ commit }, id) {
      //Histórico da Lead
      let data = await api_calls.collect_lead_process(id);

      //Set Process Id for a specific lead:
      commit('SET_GLOBAL_VARIABLE', {
        var: 'single_lead_process_id',
        value: data,
      });
    },
    collect_extra_lead_data: async function({ commit }, id) {
      let data = await api_calls.collect_extra_lead_data(id);
      commit('SET_LEAD_CENTER_PROCESS', data);
    },
    collect_report_data: async function({ commit }, payload) {
      let leads = await api_calls.collect_report_data(payload);

      commit('SET_REPORT_DATA', leads);
    },
    collect_leads_disabled: async function({ commit }, payload) {
      let data = await api_calls.collect_leads_disabled(payload);

      commit('SET_TWO_GLOBAL_VARIABLES', {
        var1: 'leads_disabled',
        value1: data,
        var2: 'doneCallLeadsDisabled',
        value2: true,
      });
    },
    collect_leads_enabled: async function({ commit }, payload) {
      let data = await api_calls.collect_leads_enabled(payload);

      //Set all leads in Leads view:
      commit('SET_TWO_GLOBAL_VARIABLES', {
        var1: 'leads_enabled',
        value1: data,
        var2: 'doneCallLeadsEnable',
        value2: true,
      });
    },
    collect_possible_results: async function({ commit }, payload) {
      let data = await api_calls.collect_possible_results(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'possibleResults', value: data });
    },
    collect_lead_history: async function({ commit }, payload) {
      let data = await api_calls.collect_lead_history(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'leadHistory', value: data });
    },
    collect_lead_documents: async function({ commit }, payload) {
      let data = await api_calls.collect_lead_documents(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'processFiles', value: data });
    },
    update_lead_details: async function({ commit }, payload) {
      let data = await api_calls.update_lead_details(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'resultApi', value: data });
    },
    collect_leads_filters: async function({ commit }, payload) {
      let data = await api_calls.collect_leads_filters(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'leadsFilters', value: data });
    },
    collect_my_callbacks: async function({ commit }, payload) {
      let data = await api_calls.collect_my_callbacks(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'leadsCallbacks', value: data });
    },
    collect_my_leads_by_step: async function({ commit }, payload) {
      let data = await api_calls.collect_my_leads_by_step(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'leadsStepByStep', value: data });
    },
    collect_new_leads_total: async function({ commit }) {
      let data = await api_calls.collect_new_leads_total();

      commit('SET_GLOBAL_VARIABLE', { var: 'newLeadsTotal', value: data });
    },
    login: async function({ commit }, payload) {
      let data = await api_calls.login(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'resultApi', value: data });
    },
    products: async function({ commit }) {
      let data = await api_calls.products();

      commit('SET_GLOBAL_VARIABLE', { var: 'leadProducts', value: data });
    },
    process_results: async function({ commit }) {
      let data = await api_calls.process_results();

      commit('SET_GLOBAL_VARIABLE', { var: 'leadProcessResults', value: data });
    },
    update_pass: async function({ commit }, payload) {
      let data = await api_calls.update_pass(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'resultApi', value: data });
    },
    refresh_leads: async function({ commit }) {
      let data = await api_calls.refresh_leads();

      commit('SET_GLOBAL_VARIABLE', { var: 'resultApi', value: data });
    },
    collect_my_goal: async function({ commit }, payload) {
      let data = await api_calls.collect_my_goal(payload);

      let value = {
        year: '',
        month: '',
        valor: '',
      };

      if (data[0].kpi != null || data[0].kpi != undefined) {
        //Transformar data
        let obj = data[0].kpi.split(',');

        for (let i in obj) {
          let a = obj[i].split(':');

          value[
            a[0].includes('{') ? a[0].replace('{', '') : a[0]
          ] = a[1].includes('}') ? Number(a[1].replace('}', '')) : Number(a[1]);
        }

        if (value.valor != null) value = value.valor;
        else value = 1;
      } else value = 1;

      commit('SET_GLOBAL_VARIABLE', { var: 'consultantGoal', value });
    },
    get_all_kpi: async function({ commit }, payload) {
      const convertKPI = (obj) => {
        let value = {
          year: '',
          month: '',
          valor: '',
        };

        for (let i in obj) {
          let a = obj[i].split(':');

          value[
            a[0].includes('{') ? a[0].replace('{', '') : a[0]
          ] = a[1].includes('}') ? Number(a[1].replace('}', '')) : Number(a[1]);
        }

        return value;
      };

      let data = await api_calls.get_all_kpi(payload);

      data = data.map((e) => ({
        name: e.name,
        kpi: e.kpi != null ? convertKPI(e.kpi.split(',')) : 1,
      }));

      commit('SET_GLOBAL_VARIABLE', { var: 'allKpis', value: data });
    },

    collect_history: async function({ commit }, payload) {
      let data = await api_calls.collect_history(payload);

      commit('SET_GLOBAL_VARIABLE', { var: 'historyClient', value: data });
    },
    leads_disabled_consultor: async function({ commit }, payload) {
      let data = await api_calls.leads_disabled_consultor(payload);
      commit('SET_GLOBAL_VARIABLE', { var: 'leads_disabled', value: data });
    },
    set_kpi: async function({ commit }, payload) {
      let data = await api_calls.set_kpi(payload);
      data = data.resultado;

      commit('SET_GLOBAL_VARIABLE', { var: 'resultApi', value: data });
    },
    simulador_seguros: async function({ commit }, payload) {
      console.log('aqui em simulador_seguros');

      let data = await api_calls.simulador_seguros(payload);
      data = data.simulations != undefined ? data.simulations : [];

      console.log('simulador_seguros', data);

      commit('SET_GLOBAL_VARIABLE', { var: 'simulador', value: data });
    },

    type_lead: function(lead) {
      let type = lead.produto;

      //console.warn('type lead', lead);

      switch (type) {
        case 'credito_consolidado':
          return 'CC';
        case 'credito_pessoal':
          return 'CP';
        case 'credito_habitacao':
          return 'CH';
        case 'seguro_de_vida':
          return 'SS';
        default:
          return 'CC';
      }
    },
  },
  modules: {},
});
