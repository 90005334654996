<template v-if="dialog">
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template> -->
      <v-card>
        <v-card-title>
          <span class="headline">Alterar Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Password Actual*"
                  type="password"
                  v-model="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password Nova*"
                  type="password"
                  v-model="newPassword"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Confirmação Password Nova*"
                  type="password"
                  v-model="newPassword2"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>{{ formMsg }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="rgb(246, 147, 30)" text dark @click="close()"
            >Fechar</v-btn
          >
          <v-btn color="rgb(246, 147, 30)" text dark @click="save()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "Password",
  props: ["dialog", "username"],
  data: () => ({
    // dialog: false,
    password: "",
    newPassword: "",
    newPassword2: "",
    formMsg: "",
  }),
  methods: {
    close() {
      this.$emit("close-dialog");
    },
    async save() {
      let pass = this.password;
      let newp = this.newPassword;
      let newp2 = this.newPassword2;

      if (pass == "" || newp == "" || newp2 == "") {
        this.formMsg = "Todos os campos são obrigatórios";
      } else if (newp != newp2) {
        this.formMsg = "As passwords não coincidem";
      } else {
        this.formMsg = "A validar...";

        let apiBody = {
          pass,
          newp,
        };

        await this.$store.dispatch("update_pass", {
          apiBody,
          username: this.username,
        });

        const dados = this.$store.state.resultApi;

        if (dados.resultado == "OK") {
          this.formMsg = "Password actualizada com sucesso!";

          this.password = "";
          this.newPassword = "";
          this.newPassword2 = "";
        } else [(this.formMsg = dados.details)];
      }
    },
  },
};
</script>

<style scoped>
.v-card-actions .v-btn {
  letter-spacing: normal;
}

.v-btn {
  padding: 5px !important;
  height: auto !important;
  letter-spacing: normal;
  font-size: 12px;
}
</style>